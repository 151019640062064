// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-tsx": () => import("./../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-regulamin-js": () => import("./../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */)
}

