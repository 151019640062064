module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#2AF5BA","theme_color":"#2AF5BA","display":"minimal-ui","icon":"src/images/sygnet.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cce80d86951ba9a0a81f43a584b54db2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate/gatsby-browser.js'),
      options: {"plugins":[],"sourceLanguage":"pl","targetLanguages":["pl","en"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PRZGRN6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
